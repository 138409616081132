<template>
  <div class="document">
    <h1 class="document-title">
      Clear Mortgage Capital Credit Report Authorization (Pre-Qual Check)
    </h1>

    <div class="document-rules">
      <ul>
        <li>
          <b>Purpose:</b> I understand that Clear Mortgage Capital, Inc. (CMC) intends to use the consumer
          credit report for the purpose of evaluating my credit in order to determine if I might be qualified to obtain
          credit.
        </li>
        <li>
          <b>Privacy:</b> I understand that my personal information will not be shared with any outside parties.
        </li>
        <li>
          <b>Alternative:</b> I understand that the alternative to pulling my report through CMC is to access
          my report through <a href="https://www.annualcreditreport.com/" target="_blank">www.annualcreditreport.com</a>.
          The report accessed through this site will not be associated with obtaining scores from
          <a href="https://www.annualcreditreport.com/" target="_blank">www.annualcreditreport.com</a>.
        </li>
        <li>
          <b>Follow-up:</b> I understand that I may choose to authorize CMC to pull a follow-up hard credit
          check after the date of this authorization, and that this may negatively affect my credit score at this time.
        </li>
      </ul>
    </div>

    <div class="document-info">
      <p>
        By signing below, I authorize Clear Mortgage Capital to obtain a consumer credit report
        for myself and/or my spouse
      </p>
      <div class="row">
        <div class="col-3 document-info-personal">
          <h2 id="fieldName">{{ docsData.firstName }} {{ docsData.lastName }}</h2>
          <label for="fieldName">Participant name</label>
        </div>
        <div class="col-1"></div>
        <div class="col-3 document-info-personal">
          <h2 id="fieldDate">{{ docsData.documentSignedOn ? formatDate(docsData.documentSignedOn) : null }}</h2>
          <label for="fieldDate">Date</label>
        </div>
        <div class="col-1"></div>
        <div class="col-4 document-info-personal">
          <h2 id="fieldSignature">{{ docsData.signature }}</h2>
          <label for="fieldSignature">Participant Signature</label>
        </div>
        <div class="col-12 mt-3 document-info-personal">
          <h2 id="fieldAddress">{{ docsData.address }}</h2>
          <label for="fieldAddress">Address</label>
        </div>
        <div class="col-12 mt-3 document-info-personal">
          <h2 id="fieldCityStateZip">{{ docsData.city }}, {{ docsData.state }} {{ docsData.zip }}</h2>
          <label for="fieldCityStateZip">City, State, ZIP</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditAuthPreview',
  props: {
    docsData: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@page {
  margin: 0;
  padding: 0;
}
.document {
  padding-top: 80px;
  &-title {
    text-align: center;
    color: #007090;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    padding: 0 120px;
    margin: 0 0 30px 0;
  }
  &-rules {
    padding: 0 80px;
    margin-bottom: 50px;

    ul {
      li {
        margin-bottom: 18px;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: normal;
        a {
          color: #27a2c7;
        }
      }
    }
  }
  &-info {
    padding: 0 65px;
    p {
      margin-bottom: 36px;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
    &-personal {
      h2 {
        position: relative;
        display: flex;
        align-items: flex-end;
        color: #000000;
        font-family: "Courier Prime";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        min-height: 22px;
        margin: 0;
        &:after {
          position: absolute;
          left: 0;
          bottom: -3.5px;
          content: '';
          height: 1px;
          width: 100%;
          background: black;
        }
      }
      label {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 2.5px;
      }
    }
  }
}

#fieldSignature {
  margin-top: -20px;
  color: #000000;
  font-family: "Safiar Signature";
  font-size: 36px;
  letter-spacing: 0;
  line-height: 42px;
  min-height: 40px;
}
</style>
